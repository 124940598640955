import assets from "../assets";

const kits = [
  {
    id: 1,
    promo: "Promo 1",
    title: "Cámara Interior",
    img: assets.camaras.interior,
    info: (
      <ul>
        <li>1 Cámara Interior</li>
        <li>1 Fuente universal</li>
        <li>Instalación sin cargo por nuestro equipo técnico</li>
        <li>Servicio de soporte y mantenimiento post venta</li>
        <li>Servicio de almacenamiento en nube hasta 1000 clips</li>
        <li>Aplicación Video Control para manejarla desde el Celular</li>
      </ul>
    ),
    instalacion: 3200,
    abono: 4300,
    link: ":kit1",
  },
  {
    id: 2,
    promo: "Promo 2",
    title: "2 Cámaras Interiores",
    img: assets.camaras.dobleinterior,
    info: (
      <ul>
        <li>2 Cámaras Interiores</li>
        <li>2 Fuente universal</li>
        <li>Servicio de soporte y mantenimiento post venta</li>
        <li>Servicio de almacenamiento en nube hasta 1000 clips</li>
        <li>Aplicación Video Control para manejarla desde el Celular</li>
      </ul>
    ),
    instalacion: 4300,
    abono: 4300,
    link: ":kit2",
  },
  {
    id: 3,
    promo: "Promo 3",
    title: "Cámara Interior + Cámara Exterior",
    img: assets.camaras.intext,
    info: (
      <ul>
        <li>1 Cámara Interior + 1 Cámara Exterior</li>
        <li>2 Fuente universal</li>
        <li>Servicio de soporte y mantenimiento post venta</li>
        <li>Servicio de almacenamiento en nube hasta 1000 clips</li>
        <li>Aplicación Video Control para manejarla desde el Celular</li>
      </ul>
    ),
    instalacion: 4300,
    abono: 4300,
    link: ":kit3",
  },
];

export default kits;
