import "./App.css";
import Header from "./Header";
import Home from "./Home";
import Cart from "./Cart";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import { useEffect, useState } from "react";
import Send from "./Send";

function App() {
  const queryParams = new URLSearchParams(window.location.search);
  const origen = queryParams.get("origen");
  const medio = queryParams.get("medio");

  const [counter, setCounter] = useState({
    hours: 17,
    minutes: 13,
    seconds: 24,
  });
  useEffect(() => {
    const interval = setInterval(() => {
      counter.seconds > 0 &&
        setCounter({ ...counter, seconds: counter.seconds - 1 });
      counter.seconds === 0
        ? setCounter({ ...counter, minutes: counter.minutes - 1, seconds: 59 })
        : counter.minutes === 0 &&
          setCounter({ hours: counter.hours - 1, minutes: 59, seconds: 59 });

      counter.hours & counter.minutes & (counter.seconds === 0) &&
        clearInterval(interval);
    }, 1000);
    return () => clearInterval(interval);
  }, [counter]);

  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route exact path="/" element={<Home counter={counter} />} />
          <Route
            path="/cart/:product"
            element={<Cart params={{ origen, medio }} counter={counter} />}
          />
          <Route path="/send" element={<Send />} />
          {/* Page Not Found */}
          <Route path="*" element={<Navigate to="/" />} />
          {/* Cart Only to Home */}
          <Route path="/cart" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
