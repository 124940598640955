import React from 'react'
import './index.css'
import assets from "../assets"

const Hero = () => {
  return (
    <div className='section hero'>
        <div className="container-lg flex flexRow">
            <div className="col70 flex flexCol relative">
                <h2 className='white'>Promo Online</h2>
                <h1 className='white'>CÁMARAS DE VIGILANCIA INTELIGENTE + APP</h1>
                <p className='white'>Tu sistema de vigilancia con cámaras IP de última generación con micrófono digital y altavoz que te permitien monitorear y controlar desde tu celu todo lo que pasa en tu casa o negocio.</p>
            </div>
            <img src={assets.camaras.interior} alt="Cámara interior" />
        </div>
    </div>
  )
}

export default Hero