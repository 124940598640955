import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import kits from "../products";
import "./index.css";

const CheckCart = () => {
  const navigate = useNavigate();

  let { product } = useParams();

  const productCart = (kits) => {
    return kits.filter(({ link }) => {
      return link === product;
    });
  };
  const productCartFilter = productCart(kits);

  return (
    <div className="minsec cartCheck">
      <div className="container-lg flex flexCol">
        <h2>Detalle del pedido</h2>
        {productCartFilter.map((p, index) => (
          <div className="flex flexRow card" key={index}>
            <div className="imgInfo flex flexRow alignCenter">
              <img src={p.img} alt="Producto" />
              <div className="info">
                <h5>{p.promo}</h5>
                <h2>
                  <strong>Kit</strong>
                  <br />
                  {p.title}
                </h2>
              </div>
            </div>
            <div className="price">
              <span>
                Instalación{" "}
                <strong>${p.instalacion.toLocaleString("en-US")}</strong>
              </span>
              <h1>${p.abono.toLocaleString("es-AR")}</h1>
              <h3>x mes</h3>
            </div>
          </div>
        ))}
        <button className="boton volver" onClick={() => navigate(-1)}>
          <svg
            viewBox="0 0 10 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 1L1 9L9 17"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Cambiar promo
        </button>
      </div>
    </div>
  );
};

export default CheckCart;
