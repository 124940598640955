import React from "react";
import { Link } from "react-router-dom";
import logo from "./../assets/logo.png";
import "./index.css";

const Header = () => {
  return (
    <div className="header">
      <div className="container-lg flex flexCol">
        <Link to={"/"}>
          <img src={logo} alt="Video Control Agente Oficial" />
        </Link>
      </div>
    </div>
  );
};

export default Header;
