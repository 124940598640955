import interior from '../assets/interior.png'
import exterior from '../assets/exterior.png'
import dobleinterior from '../assets/dobleinterior.png'
import intext from '../assets/intext.png'
import phone from '../assets/phone.png'

const assets = {
    phone: phone,
    camaras: {
        interior: interior,
        exterior: exterior,
        dobleinterior: dobleinterior,
        intext: intext
    },
    icons : {
        icon1: <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="143"
                    height="110"
                    fill="none"
                    viewBox="0 0 143 110"
                >
                    <path
                    stroke="#FF822E"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="5.124"
                    d="M87.538 58.123c-2.561 6.084-8.325 10.567-15.69 10.567-9.287 0-16.652-7.365-16.652-16.651 0-7.045 4.483-13.13 10.567-15.691-.64 1.921-1.28 3.843-1.28 6.084 0 9.286 7.364 16.651 16.65 16.651 2.563.32 4.484 0 6.405-.96z"
                    ></path>
                    <path
                    stroke="#FF822E"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="5.124"
                    d="M139.734 53.32s-24.016 32.662-68.207 32.662C27.017 85.982 3 53.319 3 53.319s24.017-32.662 68.207-32.662c44.511 0 68.527 32.662 68.527 32.662z"
                    ></path>
                    <path
                    stroke="#FF822E"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="5.124"
                    d="M45.27 33.786c6.083-8.005 15.37-13.129 25.937-13.129 17.932 0 32.662 14.73 32.662 32.662 0 17.933-14.73 32.663-32.662 32.663s-32.662-14.73-32.662-32.663c0-2.561.32-4.803.64-7.044M23.814 17.775V2.725h14.73M23.814 92.066v14.73h14.73M118.92 17.775V2.725h-14.73M118.92 92.066v14.73h-14.73"
                    ></path>
                </svg>,
        icon2: <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="103"
                    height="120"
                    fill="none"
                    viewBox="0 0 103 120"
                >
                    <path
                    stroke="#FF822E"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="4.163"
                    d="M50.072 117.319H60"
                    ></path>
                    <path
                    stroke="#FF822E"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="4.803"
                    d="M27.657 65.123h12.488M40.146 94.903h9.926M42.707 82.415l7.365 2.562M22.854 55.196l12.168-2.562 7.685 2.562M12.927 72.488C6.202 72.488 3 67.044 3 59.999v-5.123c0-2.882 2.242-5.124 5.124-5.124h5.123M50.072 67.364h19.854V32.781H50.072M69.926 50.072h12.489M69.926 40.146h12.489M69.926 60h12.489M50.072 60v12.488M62.561 67.364v12.489M50.072 12.927v12.168M62.561 20.292v12.489"
                    ></path>
                    <path
                    stroke="#FF822E"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="4.803"
                    d="M50.072 57.438H60v-14.73h-9.927"
                    ></path>
                    <path
                    stroke="#FF822E"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="4.803"
                    d="M50.072 3C29.578 3 12.927 19.651 12.927 40.146l1.921 39.707c.32 8.006 3.843 15.37 9.607 20.494l12.809 11.528c3.522 3.202 8.325 5.124 13.129 5.124V3h-.32zM50.072 3h7.365M70.567 12.927h7.685M50.072 12.927h12.489M69.926 22.854h12.489M69.926 77.291h15.05M50.072 87.218h32.343M78.252 97.145h4.803M50.072 97.145h19.854M50.072 107.392h19.854M92.982 77.291h5.124M90.42 50.072h5.124M92.982 22.854h5.124M92.982 40.146h7.365M95.544 60h4.803"
                    ></path>
                </svg>,
        icon3: <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="117"
                    height="101"
                    fill="none"
                    viewBox="0 0 117 101"
                >
                    <path
                    fill="#FF822E"
                    d="M58.72 79.336c-1.281 0-2.562-.96-2.562-2.562V26.82c0-1.281.96-2.562 2.562-2.562 1.28 0 2.561.96 2.561 2.562v49.954c0 1.281-.96 2.562-2.561 2.562z"
                    ></path>
                    <path
                    stroke="#FF822E"
                    strokeMiterlimit="10"
                    strokeWidth="0.961"
                    d="M58.72 79.336c-1.281 0-2.562-.96-2.562-2.562V26.82c0-1.281.96-2.562 2.562-2.562 1.28 0 2.561.96 2.561 2.562v49.954c0 1.281-.96 2.562-2.561 2.562z"
                    ></path>
                    <path
                    fill="#FF822E"
                    d="M68.647 69.73c-1.281 0-2.562-.961-2.562-2.562V36.426c0-1.28.96-2.561 2.562-2.561 1.28 0 2.561.96 2.561 2.561v30.742c0 1.6-.96 2.561-2.561 2.561z"
                    ></path>
                    <path
                    stroke="#FF822E"
                    strokeMiterlimit="10"
                    strokeWidth="0.961"
                    d="M68.647 69.73c-1.281 0-2.562-.961-2.562-2.562V36.426c0-1.28.96-2.561 2.562-2.561 1.28 0 2.561.96 2.561 2.561v30.742c0 1.6-.96 2.561-2.561 2.561z"
                    ></path>
                    <path
                    fill="#FF822E"
                    d="M78.573 60.443c-1.28 0-2.561-.96-2.561-2.562V45.393c0-1.281.96-2.562 2.561-2.562 1.281 0 2.562.96 2.562 2.562V57.88c0 1.601-.96 2.562-2.562 2.562z"
                    ></path>
                    <path
                    stroke="#FF822E"
                    strokeMiterlimit="10"
                    strokeWidth="0.961"
                    d="M78.573 60.443c-1.28 0-2.561-.96-2.561-2.562V45.393c0-1.281.96-2.562 2.561-2.562 1.281 0 2.562.96 2.562 2.562V57.88c0 1.601-.96 2.562-2.562 2.562z"
                    ></path>
                    <path
                    fill="#FF822E"
                    d="M88.82 57.561c-1.28 0-2.561-.96-2.561-2.562v-6.404c0-1.281.96-2.562 2.561-2.562 1.281 0 2.562.96 2.562 2.562v6.404c-.32 1.601-1.28 2.562-2.562 2.562z"
                    ></path>
                    <path
                    stroke="#FF822E"
                    strokeMiterlimit="10"
                    strokeWidth="0.961"
                    d="M88.82 57.561c-1.28 0-2.561-.96-2.561-2.562v-6.404c0-1.281.96-2.562 2.561-2.562 1.281 0 2.562.96 2.562 2.562v6.404c-.32 1.601-1.28 2.562-2.562 2.562z"
                    ></path>
                    <path
                    fill="#FF822E"
                    d="M48.793 69.73c-1.281 0-2.562-.961-2.562-2.562V36.426c0-1.28.96-2.561 2.562-2.561 1.28 0 2.562.96 2.562 2.561v30.742c0 1.6-1.281 2.561-2.562 2.561z"
                    ></path>
                    <path
                    stroke="#FF822E"
                    strokeMiterlimit="10"
                    strokeWidth="0.961"
                    d="M48.793 69.73c-1.281 0-2.562-.961-2.562-2.562V36.426c0-1.28.96-2.561 2.562-2.561 1.28 0 2.562.96 2.562 2.561v30.742c0 1.6-1.281 2.561-2.562 2.561z"
                    ></path>
                    <path
                    fill="#FF822E"
                    d="M38.866 60.443c-1.28 0-2.562-.96-2.562-2.562V45.393c0-1.281.96-2.562 2.562-2.562 1.28 0 2.562.96 2.562 2.562V57.88c0 1.601-1.281 2.562-2.562 2.562z"
                    ></path>
                    <path
                    stroke="#FF822E"
                    strokeMiterlimit="10"
                    strokeWidth="0.961"
                    d="M38.866 60.443c-1.28 0-2.562-.96-2.562-2.562V45.393c0-1.281.96-2.562 2.562-2.562 1.28 0 2.562.96 2.562 2.562V57.88c0 1.601-1.281 2.562-2.562 2.562z"
                    ></path>
                    <path
                    fill="#FF822E"
                    d="M28.94 57.561c-1.282 0-2.563-.96-2.563-2.562v-6.404c0-1.281.961-2.562 2.562-2.562 1.281 0 2.562.96 2.562 2.562v6.404c0 1.601-1.28 2.562-2.562 2.562z"
                    ></path>
                    <path
                    stroke="#FF822E"
                    strokeMiterlimit="10"
                    strokeWidth="0.961"
                    d="M28.94 57.561c-1.282 0-2.563-.96-2.563-2.562v-6.404c0-1.281.961-2.562 2.562-2.562 1.281 0 2.562.96 2.562 2.562v6.404c0 1.601-1.28 2.562-2.562 2.562z"
                    ></path>
                    <path
                    fill="#FF822E"
                    d="M19.012 56.92c-1.28 0-2.561-.96-2.561-2.561v-5.124c0-1.28.96-2.561 2.561-2.561 1.281 0 2.562.96 2.562 2.561v5.124c0 1.28-1.28 2.562-2.562 2.562z"
                    ></path>
                    <path
                    stroke="#FF822E"
                    strokeMiterlimit="10"
                    strokeWidth="0.961"
                    d="M19.012 56.92c-1.28 0-2.561-.96-2.561-2.561v-5.124c0-1.28.96-2.561 2.561-2.561 1.281 0 2.562.96 2.562 2.561v5.124c0 1.28-1.28 2.562-2.562 2.562z"
                    ></path>
                    <path
                    fill="#FF822E"
                    d="M98.747 56.92c-1.28 0-2.561-.96-2.561-2.561v-5.124c0-1.28.96-2.561 2.561-2.561 1.281 0 2.562.96 2.562 2.561v5.124c-.32 1.28-1.281 2.562-2.562 2.562z"
                    ></path>
                    <path
                    stroke="#FF822E"
                    strokeMiterlimit="10"
                    strokeWidth="0.961"
                    d="M98.747 56.92c-1.28 0-2.561-.96-2.561-2.561v-5.124c0-1.28.96-2.561 2.561-2.561 1.281 0 2.562.96 2.562 2.561v5.124c-.32 1.28-1.281 2.562-2.562 2.562z"
                    ></path>
                    <path
                    fill="#FF822E"
                    d="M47.512 96.308H28.62c-5.764 0-10.567-4.804-10.567-10.568V69.41l9.606 9.606c.961.96 2.242.96 2.882 0 .961-.96.961-2.242 0-2.882l-13.129-13.13c-.64-.64-1.921-.64-2.562 0L1.72 76.135c-.96.96-.96 2.242 0 2.882.961.96 2.242.96 2.882 0l9.607-9.607v16.652a14.35 14.35 0 0014.41 14.41h19.213c1.281 0 2.242-.961 2.242-2.242-.64-.96-1.601-1.921-2.562-1.921z"
                    ></path>
                    <path
                    stroke="#FF822E"
                    strokeMiterlimit="10"
                    strokeWidth="0.961"
                    d="M47.512 96.308H28.62c-5.764 0-10.567-4.804-10.567-10.568V69.41l9.606 9.606c.961.96 2.242.96 2.882 0 .961-.96.961-2.242 0-2.882l-13.129-13.13c-.64-.64-1.921-.64-2.562 0L1.72 76.135c-.96.96-.96 2.242 0 2.882.961.96 2.242.96 2.882 0l9.607-9.607v16.652a14.35 14.35 0 0014.41 14.41h19.213c1.281 0 2.242-.961 2.242-2.242-.64-.96-1.601-1.921-2.562-1.921z"
                    ></path>
                    <path
                    fill="#FF822E"
                    d="M115.078 22.017c-.96-.961-2.241-.961-2.881 0l-9.607 9.606V14.972A14.35 14.35 0 0088.18.562H68.967c-1.281 0-2.242.96-2.242 2.241 0 1.281.96 1.922 2.242 1.922H87.86c5.764 0 10.567 4.803 10.567 10.567v16.331l-9.607-9.606c-.96-.961-2.241-.961-2.881 0-.961.96-.961 2.241 0 2.882l13.129 13.129c.64.64 1.921.64 2.561 0l13.129-13.13c.961-.64.961-1.92.32-2.881z"
                    ></path>
                    <path
                    stroke="#FF822E"
                    strokeMiterlimit="10"
                    strokeWidth="0.961"
                    d="M115.078 22.017c-.96-.961-2.241-.961-2.881 0l-9.607 9.606V14.972A14.35 14.35 0 0088.18.562H68.967c-1.281 0-2.242.96-2.242 2.241 0 1.281.96 1.922 2.242 1.922H87.86c5.764 0 10.567 4.803 10.567 10.567v16.331l-9.607-9.606c-.96-.961-2.241-.961-2.881 0-.961.96-.961 2.241 0 2.882l13.129 13.129c.64.64 1.921.64 2.561 0l13.129-13.13c.961-.64.961-1.92.32-2.881z"
                    ></path>
                </svg>,
        icon4: <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="151"
                    height="98"
                    fill="none"
                    viewBox="0 0 151 98"
                >
                    <path
                    stroke="#FF822E"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="6.404"
                    d="M60.68 30.106c7.044-15.37 22.735-26.258 40.987-26.258 24.977 0 45.151 20.174 45.151 45.151 0 24.977-20.174 45.151-45.151 45.151H34.421C17.77 94.15 4 80.701 4 63.73c0-16.652 13.45-30.421 30.421-30.421 3.522 0 7.045.64 10.247 1.6"
                    ></path>
                    <path
                    fill="#FF822E"
                    d="M100.386 78.78H51.713c-.64 0-1.281-.64-1.281-1.281V44.196c0-.64.64-1.28 1.28-1.28h48.674c.641 0 1.281.64 1.281 1.28v33.303c0 .64-.64 1.28-1.281 1.28zm-47.392-2.562h46.112V45.477H52.994v30.741z"
                    ></path>
                    <path
                    stroke="#FF822E"
                    strokeMiterlimit="10"
                    strokeWidth="2.402"
                    d="M100.386 78.78H51.713c-.64 0-1.281-.64-1.281-1.281V44.196c0-.64.64-1.28 1.28-1.28h48.674c.641 0 1.281.64 1.281 1.28v33.303c0 .64-.64 1.28-1.281 1.28zm-47.392-2.562h46.112V45.477H52.994v30.741z"
                    ></path>
                    <path
                    fill="#FF822E"
                    d="M72.527 67.252h-.32c-.32-.32-.32-.32-.32-.96V55.082c0-.32.32-.64.32-.96.32-.32.64 0 .96 0l9.287 5.444c.32.32.32.32.32.64s-.32.64-.32.64l-9.286 5.444c0 .96-.32.96-.64.96z"
                    ></path>
                    <path
                    stroke="#FF822E"
                    strokeMiterlimit="10"
                    strokeWidth="1.601"
                    d="M72.527 67.252h-.32c-.32-.32-.32-.32-.32-.96V55.082c0-.32.32-.64.32-.96.32-.32.64 0 .96 0l9.287 5.444c.32.32.32.32.32.64s-.32.64-.32.64l-9.286 5.444c0 .96-.32.96-.64.96z"
                    ></path>
                </svg>
    }
}

export default assets