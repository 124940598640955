import React from 'react'
import assets from '../assets'
import './index.css'

const Control = () => {
  return (
    <div className='section control'>
        <div className="container-lg flex flexRow gap alignCenter">
            <div className="col70 flex flexCol">
                <h1>Control total desde tu celular o tablet y desde donde estés</h1>
                <p>Cámara Interior IP, Micrófono digital y altavoz que te permitie monitorear
                desde tu celu todo lo que pasa en tu casa o negocio.</p>
                <a className='boton' href="#products">Contratar ahora</a>
            </div>
            <img src={assets.phone} alt="App" />
        </div>
    </div>
  )
}

export default Control