import visa from '../assets/visa.svg'
import mastercard from '../assets/mastercard.svg'
import american from '../assets/american.svg'
import shopping from '../assets/shopping.svg'
import cencosud from '../assets/cencosud.svg'
import cabal from '../assets/cabal.svg'
import naranja from '../assets/naranja.svg'
import nativa from '../assets/nativa.svg'
import argencard from '../assets/argencard.svg'

export const tarjetas = [
    visa,
    mastercard,
    american,
    shopping,
    cencosud,
    cabal,
    naranja,
    nativa,
    argencard,
]