import React from 'react'
import './index.css'
import { tarjetas } from './tarjetas'

const Sign = () => {

  return (
    <>
      <div className='sign'>
          <div className="container-lg flex flexCol">
              <h2 className='white'>Contratando online tenés 12 meses con <strong>25% de descuento</strong></h2>
          </div>
      </div>
      <div className="container-lg flex flexCol">
        <div className="flex flexRow flexWrap tarjetas">
          <h3>Exclusivo usuarios de:</h3>
          {
            tarjetas.map((tarjeta, index) => (
              <img src={tarjeta} alt={'Tarjeta'+index} key={index}/>
            ))
          }
        </div>
      </div>
    
    </>
  )
}

export default Sign