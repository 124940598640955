/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import PhoneInput, {
  formatPhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import Select from "react-select";

const optionsSelect = [
  { label: "Visa", value: "visa" },
  { label: "MasterCard", value: "mastercard" },
  { label: "American Express", value: "americanexpress" },
  { label: "Tarjeta Shopping", value: "tarjeta shopping" },
  { label: "Cencosud", value: "cencosud" },
  { label: "Cabal", value: "cabal" },
  { label: "Naranja", value: "naranja" },
  { label: "Nativa", value: "nativa" },
  { label: "Argencard", value: "argencard" },
];

const Form = ({ params }) => {
  const navigate = useNavigate();

  const origen = params.origen;
  const medio = params.medio;

  const [error, setError] = useState("");

  const [data, setData] = useState({
    name: "",
    lastname: "",
    dni: "",
    email: "",
    address: "",
    city: "",
    province: "",
    postalcode: "",
    veriPhone: "",
    wifi: "true",
    kit: "",
    origen: origen,
    medio: medio,
    card: [],
  });

  const handleInputChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const [cards, setCards] = useState([]);

  useEffect(() => {
    cards.length > 0 && setError("");
    setData({
      ...data,
      card: cards,
    });
  }, [cards]);

  const [checkPhone, setCheckPhone] = useState("");

  useEffect(() => {
    try {
      if (isValidPhoneNumber(checkPhone)) {
        return setData({ ...data, veriPhone: formatPhoneNumber(checkPhone) });
      } else {
        return setData({ ...data, veriPhone: "" });
      }
    } catch (error) {
      console.log(error);
      console.clear();
    }
  }, [checkPhone]);

  const [formLoading, setFormLoading] = useState(false);

  const urlHook = "https://hook.us1.make.com/k7j2pnrlv0v3pp7thrzq1ojtedycehsi";
  const form = useRef(null);

  const onSubmit = async (formData) => {
    formData.preventDefault();

    if (cards.length === 0) {
      setError("Seleccione al menos una tarjeta de credito");
      return false;
    }
    if (!isValidPhoneNumber(checkPhone)) {
      return false;
    }

    console.log(formData);
    setFormLoading(true);
    setError("");

    try {
      await axios
        .post(urlHook, data, {
          Accept: "application/json",
          "Content-Type": "application/json",
        })
        .then(function (response) {
          form.current.reset();
          //Success ----------------------------------------------------
          console.log("Form Success", response);

          try {
            // eslint-disable-next-line no-undef
            gtag("event", "Goal", {
              event_category: `Click Button Form`,
              event_label: "Form",
            });
          } catch (errorC) {
            console.log(errorC);
          }
          try {
            // eslint-disable-next-line no-undef
            fbq("track", "Purchase", { value: 3349, currency: "ARS" });
          } catch (errorC) {
            console.log(errorC);
          }
          navigate("/send");
        })
        //End Succes ----------------------------------------------------
        .catch(function (error) {
          //Error ----------------------------------------------------
          console.log(error.response.data.message);
          console.log("error un problema");
          setError("Hubo un problema al enviar los datos");
          setFormLoading(false);
          try {
            // eslint-disable-next-line no-undef
            gtag("event", "Forms", {
              event_category: "Form Error",
              event_label: `${error}`,
            });
          } catch (errorC) {
            console.log(errorC);
          }
        });
      //End Error ----------------------------------------------------
    } catch (error) {
      console.log(error);
      setError("Hubo un problema al enviar los datos");
      setFormLoading(false);
      try {
        // eslint-disable-next-line no-undef
        gtag("event", "Forms", {
          event_category: "Form Error",
          event_label: `${error}`,
        });
      } catch (errorC) {
        console.log(errorC);
      }
    }
  };

  let { product } = useParams();

  const listParam = {
    ":kit1": "Kit 1 Cam Int",
    ":kit2": "Kit 2 Cam Int",
    ":kit3": "Kit 2 Cam Int-Ext",
  };

  useEffect(() => {
    setData({
      ...data,
      kit: listParam[product] || listParam[":kit1"],
    });
  }, [product]);

  /* Capture Email */

  const webhookEmail =
    "https://hook.us1.make.com/4izky55lbc1n6ypvxhk0a4muyolki2j5";

  const captureMicroData = async (e) => {
    let microData = {
      name: data.name,
      phone: data.veriPhone,
      email: data.email,
    };
    console.log(microData);
    try {
      await axios
        .post(webhookEmail, microData, {
          Accept: "application/json",
          "Content-Type": "application/json",
        })
        .then(function (response) {
          console.log(response);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="minsec formSec noPadTop">
      <div className="container-lg flex flexCol">
        <h2>Completá tus datos y recibí todos los detalles</h2>
        <p>
          Importante: Aún no abonarás nada. Una vez recibida tu consulta nuestro
          equipo se pondrá en contacto para darte todos los detalles.
        </p>
        <form
          ref={form}
          className="form flex flexRow flexWrap justifySpace"
          onSubmit={(e) => onSubmit(e)}
          autoComplete="off"
        >
          <input
            className="input50"
            type="text"
            name="name"
            id="name"
            placeholder="Nombre"
            value={data.name}
            maxLength={80}
            onChange={handleInputChange}
            required
          />
          <input
            className="input50"
            type="text"
            name="lastname"
            id="lastname"
            placeholder="Apellido"
            value={data.lastname}
            maxLength={100}
            onChange={handleInputChange}
            required
          />

          <input
            className="input100"
            type="number"
            name="dni"
            id="dni"
            placeholder="DNI"
            value={data.dni}
            minLength={8}
            maxLength={8}
            onChange={handleInputChange}
            required
          />
          <PhoneInput
            className={
              checkPhone && !isValidPhoneNumber(checkPhone)
                ? "errorInput input100"
                : "input100"
            }
            country="AR"
            defaultCountry="AR"
            placeholder="Código de Área y Teléfono"
            value={checkPhone}
            onChange={setCheckPhone}
            autoComplete="nope"
            required
          />
          <input
            className="input100"
            type="email"
            name="email"
            id="email"
            placeholder="Email"
            onChange={handleInputChange}
            onBlur={captureMicroData}
            autoComplete="nope"
            required
          />
          <input
            className="input25"
            type="text"
            name="address"
            id="address"
            placeholder="Dirección de instalación"
            value={data.address}
            onChange={handleInputChange}
            required
          />
          <input
            className="input25"
            type="text"
            name="city"
            id="city"
            placeholder="Ciudad o localidad"
            value={data.city}
            onChange={handleInputChange}
            required
          />
          <input
            className="input25"
            type="text"
            name="province"
            id="province"
            placeholder="Provincia"
            value={data.province}
            onChange={handleInputChange}
            required
          />
          <input
            className="input25"
            type="text"
            name="postalcode"
            id="postalcode"
            placeholder="Código Postal"
            value={data.postalcode}
            maxLength="5"
            onChange={handleInputChange}
            required
          />

          <div className="input50 flex flexRow alignCenter justifySpace radioDiv">
            <p>¿Tiene Wifi en su domicilio?</p>
            <div className="radios flex flexRow gap ">
              <input
                label="Si tengo Wifi"
                type="radio"
                name="wifi"
                value="true"
                defaultChecked
                onChange={handleInputChange}
                required
              />
              <input
                label="No tengo Wifi"
                type="radio"
                name="wifi"
                value="false"
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <Select
            className="input50 cardInput"
            options={optionsSelect}
            onChange={setCards}
            isMulti={true}
            placeholder="Seleccione las tarjetas de crédito con las que opera"
            name="card"
            required
          />
          <div className="errores flex flexCol">
            <span className="error">
              {checkPhone &&
                !isValidPhoneNumber(checkPhone) &&
                "Uy, parece que tu numero de celular no es correcto"}
            </span>
            <span className="error">{error}</span>
          </div>

          <button
            className={formLoading ? "boton loadBoton" : "boton"}
            type="submit"
          >
            {formLoading ? "Enviando..." : "Enviar consulta"}
          </button>
        </form>

        {/*   <pre style={{width:'100%', overflowWrap: 'break-word'}}>
                    <code style={{ whiteSpace: 'pre-wrap'}}>

                {
                    JSON.stringify(data)
                }
                    </code>

                </pre>   */}
      </div>
    </div>
  );
};

export default Form;
