import React from 'react'
import './index.css'

const Countdown = ({title, counter}) => {
    
    return (
        <div className='flex flexCol counterDiv'>
            <div className="container-lg flex flexCol alignCenter textCenter">
                <h2>{title}</h2>
                <div className="flex flexRow counter alignCenter justifySpace">
                    <div className="hours flex flexCol">
                        {counter.hours} <span>Horas</span>
                    </div>
                    <div className="minutes flex flexCol">
                        {counter.minutes} <span>Minutos</span>
                    </div>
                    <div className="seconds flex flexCol">
                        {counter.seconds} <span>Segundos</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Countdown