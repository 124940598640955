import React from "react";
import "./index.css";

const PFrecuentes = () => {
  return (
    <div className="section preguntas graySec">
      <div className="container-lg flex flexCol">
        <h1>Con grandes ventajas</h1>
        <ul>
          <li>
            <strong>Control total desde tu App</strong>
            <br />
            Mantén el control desde tu smartphone o desde la PC a través de la
            plataforma.
          </li>
          <li>
            <strong>Comunicación al instante</strong>
            <br />
            Tiene audio bidireccional, lo que permite hablar y escuchar con la
            persona que aparece en la imagen.
          </li>
          <li>
            <strong>Visión y grabación noctura</strong>
            <br />
            Permite registrar imágenes nocturnas con toda claridad.
          </li>
          <li>
            <strong>Almacenamiento de videos</strong>
            <br />
            Guarda tus clips de video en la nube.
          </li>
          <li>
            <strong>Análisis de video</strong>
            <br />
            Realiza análisis de video por Inteligencia Artificial. Identifica si
            lo que está viendo es una persona, una mascota o un objeto.
          </li>
          <li>
            <strong>Asistencia remota y soporte</strong>
            <br />
            Para guiarte durante en la instalación. Tiene Soporte telefónico
            post instalación.
          </li>
          <li>
            <strong>
              Notificaciones en tiempo real y comunicación directa por audio
            </strong>
            <br />
            Graba clips cuando tú quieras o de forma automática según reglas que
            establezcas. Esto permite, por ejemplo, avisarte cuando alguien
            entró a la cocina de madrugada, aún en completa oscuridad.
          </li>
        </ul>
        <a className="boton" href="#products">
          Contratar Ahora
        </a>
      </div>
    </div>
  );
};

export default PFrecuentes;
