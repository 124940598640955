import React from "react";
import CheckCart from "./CheckCart";
/* import Countdown from "./Countdown"; */
import Form from "./Form";
/* import HeroCart from "./HeroCart"; */

const Cart = ({ params, counter }) => {
  return (
    <>
      {/* <HeroCart /> */}
      {/* <Countdown title="Esta promo finaliza en" counter={counter} /> */}
      <CheckCart />
      <Form params={params} />
    </>
  );
};

export default Cart;
