import React from 'react'
import Control from './Control'
import Countdown from './Countdown'
import Hero from './Hero'
import IconSection from './IconSection'
import PFrecuentes from './PFrecuentes'
import ProduSec from './ProduSec'
import Sign from './Sign'

const Home = ({counter}) => {
  return (
    <>
        <Hero />
        <IconSection />
        <ProduSec />
        <Sign />
        <Countdown title='La promo finaliza en' counter={counter}/>
        <Control />
        <PFrecuentes />
    </>
  )
}

export default Home