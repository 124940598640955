import React from 'react'
import assets from '../assets'
import './index.css'

const IconSection = () => {

    const iconsInfo = [
        {
            svg: assets.icons.icon1,
            text: <h4><strong>Visión nocturna</strong><br />de gran ángulo</h4>       
        },
        {
            svg: assets.icons.icon2,
            text: <h4>Inteligencia<strong><br />Artificial</strong></h4>       
        },
        {
            svg: assets.icons.icon3,
            text: <h4>Audio<strong><br />Bidireccional</strong></h4>       
        },
        {
            svg: assets.icons.icon4,
            text: <h4>Grabación en<strong><br />la nube</strong></h4>       
        },
    ]

    return (
        <div className='section iconSection noPadBot'>
            <div className="container-lg flex flexRow justifyAround textCenter flexWrap">
                {
                    iconsInfo.map((icon, index) => (
                        <div className='col' key={index}>
                            {icon.svg}
                            {icon.text}
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default IconSection