import React from 'react'

const Send = () => {
  return (
    <div className='section heroCart flex flexCol alignCenter' style={{height: '600px', justifyContent: 'center'}}>
        <div className="container-lg flex flexRow">
            <div className="flex flexCol relative">
                <h2 className='white'>Hemos recibido tus datos</h2>
                <h1 className='white'>¡Gracias!</h1>
                <p className='white'>En breve, un asesor se contactará para coordinar forma de pago y modalidad.</p>
            </div>
        </div>
    </div>
  )
}

export default Send