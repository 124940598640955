import React from "react";
import "./index.css";
import kits from "../products";
import { Link } from "react-router-dom";

const ProduSec = () => {
  const handleSendEvent = (value, item, itemid) => {
    // Event FB
    try {
      //  eslint-disable-next-line no-undef
      fbq("track", "AddToCart");
    } catch (error) {
      console.log(error);
    }

    // Event GA 4
    try {
      //  eslint-disable-next-line no-undef
      gtag("event", "add_shipping_info", {
        currency: "ARS",
        value: value,
        items: [
          {
            item_id: itemid,
            item_name: item,
          },
        ],
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="section products" id="products">
      <div className="container-lg flex flexCol gap">
        {kits.map((kit, index) => (
          <div className={"card card" + index} key={index}>
            <img src={kit.img} alt="Camara Promo" />
            <div className="info">
              <h5>{kit.promo}</h5>
              <h2>
                <strong>Kit</strong>
                <br />
                {kit.title}
              </h2>
              {kit.info}
            </div>
            <div className="price">
              {/*  {kit.instalacion === 0 && (
                <>
                  <div className="blobDesc">
                    <span className="white">Equipo + Instalación</span>
                    <h2 className="white">$0</h2>
                  </div>
                </>
              )}
 */}
              <span>
                Promo instalación{" "}
                <strong>${kit.instalacion.toLocaleString("es-AR")}</strong>
              </span>
              <h1>${kit.abono.toLocaleString("es-AR")}</h1>
              <h3>x mes</h3>
              <h4>12 Meses al 25%</h4>
              <Link
                className="boton"
                to={"/cart/" + kit.link}
                onClick={() => handleSendEvent(kit.abono, kit.title, kit.id)}
              >
                Pedir Info
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProduSec;
